import { Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { ManagerGuard } from './guards/manager.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./components/layout/layout.component').then(
        (m) => m.LayoutComponent
      ),
    children: [
      {
        path: '',
        redirectTo: '/analytics',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        loadComponent: () =>
          import('./pages/overview/overview.component').then(
            (m) => m.OverviewComponent
          ),
        title: 'Overview | MT',
      },
      {
        path: 'analytics',
        canActivate: [ManagerGuard],
        loadComponent: () =>
          import('./pages/analytics/analytics.component').then(
            (m) => m.AnalyticsComponent
          ),
        title: 'Analytics | MT',
      },
      {
        path: 'settings',
        children: [
          {
            path: 'users',
            canActivate: [AdminGuard],
            loadComponent: () =>
              import('./pages/users-settings/users-settings.component').then(
                (m) => m.UsersSettingsComponent
              ),
            title: 'Users | Settings | MT',
          },
          {
            path: 'org-charts',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import('./pages/org-charts/org-charts.component').then(
                (m) => m.OrgChartsComponent
              ),
            title: 'ORG Charts | Settings | MT',
          },
          {
            path: 'projects',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import(
                './pages/projects-settings/projects-settings.component'
              ).then((m) => m.ProjectsSettingsComponent),
            title: 'Projects | Settings | MT',
          },
          {
            path: 'clients',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './pages/clients-settings/clients-settings.component'
                  ).then((m) => m.ClientsSettingsComponent),
                title: 'Clients | Settings | MT',
              },
              {
                path: 'ams',
                canActivate: [ManagerGuard],
                loadComponent: () =>
                  import('./pages/ams-settings/ams-settings.component').then(
                    (m) => m.AmsSettingsComponent
                  ),
                title: 'AMs | Settings | MT',
              },
              {
                path: 'ams-supervisor',
                canActivate: [ManagerGuard],
                loadComponent: () =>
                  import(
                    './pages/ams-supervisor-settings/ams-supervisor-settings.component'
                  ).then((m) => m.AmsSupervisorSettingsComponent),
                title: 'AMs Supervisor | Settings | MT',
              },
              {
                path: 'pms',
                canActivate: [ManagerGuard],
                loadComponent: () =>
                  import('./pages/pms-settings/pms-settings.component').then(
                    (m) => m.PmsSettingsComponent
                  ),
                title: 'PMs | Settings | MT',
              },
              {
                path: 'aes',
                canActivate: [ManagerGuard],
                loadComponent: () =>
                  import('./pages/aes-settings/aes-settings.component').then(
                    (m) => m.AesSettingsComponent
                  ),
                title: 'AEs | Settings | MT',
              },
            ],
          },
        ],
      },
      {
        path: 'expenses',
        children: [
          {
            path: 'backlink',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import(
                './pages/expenses-backlink/expenses-backlink.component'
              ).then((m) => m.ExpensesBacklinkComponent),
            title: 'Backlink | Expenses | MT',
          },
          {
            path: 'content',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import(
                './pages/expenses-content/expenses-content.component'
              ).then((m) => m.ExpensesContentComponent),
            title: 'Content | Expenses | MT',
          },
          {
            path: 'ppc',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import('./pages/expenses-ppc/expenses-ppc.component').then(
                (m) => m.ExpensesPpcComponent
              ),
            title: 'PPC | Expenses | MT',
          },
        ],
      },
      {
        path: 'accounting',
        children: [
          {
            path: 'dashboard',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import(
                './pages/accounting-dashboard/accounting-dashboard.component'
              ).then((m) => m.AccountingDashboardComponent),
            title: 'Dashboard | Accounting | MT',
          },
          {
            path: 'invoices',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import('./pages/invoices/invoices.component').then(
                (m) => m.InvoicesComponent
              ),
            title: 'Invoices | Accounting | MT',
          },
          {
            path: 'ams-commission',
            canActivate: [AdminGuard],
            loadComponent: () =>
              import('./pages/ams-commission/ams-commission.component').then(
                (m) => m.AmsCommissionComponent
              ),
            title: 'AMs Commission | Accounting | MT',
          },
          {
            path: 'zakhar-invoice-splitter',
            canActivate: [AdminGuard],
            loadComponent: () =>
              import(
                './pages/zakhar-invoice-splitter/zakhar-invoice-splitter.component'
              ).then((m) => m.ZakharInvoiceSplitterComponent),
            title: 'Zakhar Invoice Splitter | Accounting | MT',
          },
        ],
      },
      {
        path: 'tools',
        children: [
          {
            path: 'ai',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import('./pages/ai/ai.component').then((m) => m.AiComponent),
            title: 'AI | Tools | MT',
          },
          {
            path: 'content-table',
            canActivate: [ManagerGuard],
            loadComponent: () =>
              import('./pages/content-table/content-table.component').then(
                (m) => m.ContentTableComponent
              ),
            title: 'Content Table | Tools | MT',
          },
          {
            path: 'backlinks',
            children: [
              {
                path: 'database',
                canActivate: [ManagerGuard],
                loadComponent: () =>
                  import('./pages/backlink-db/backlink-db.component').then(
                    (m) => m.BackLinkDBComponent
                  ),
                title: 'BackLinks DB | Tools | MT',
              },
              {
                path: 'table',
                canActivate: [ManagerGuard],
                loadComponent: () =>
                  import(
                    './pages/backlink-table/backlink-table.component'
                  ).then((m) => m.BackLinkTableComponent),
                title: 'BackLinks Table | Tools | MT',
              },
            ],
          },
        ],
      },
      {
        path: 'lead-center',
        children: [
          {
            path: 'overview',
            loadComponent: () =>
              import(
                './pages/lead-center-overview/lead-center-overview.component'
              ).then((m) => m.LeadCenterOverviewComponent),
            title: 'Overview | Lead Center | MT',
          },
          {
            path: 'settings',
            loadComponent: () =>
              import(
                './pages/lead-center-settings/lead-center-settings.component'
              ).then((m) => m.LeadCenterSettingsComponent),
            title: 'Settings | Lead Center | MT',
            children: [
              {
                path: 'data-extractor',
                loadComponent: () =>
                  import(
                    './pages/lead-center-settings/lead-center-settings.component'
                  ).then((m) => m.LeadCenterSettingsComponent),
                title: 'Data Extractor | Settings | Lead Center | MT',
              },
              {
                path: 'lead-extractor',
                loadComponent: () =>
                  import(
                    './pages/lead-center-settings/lead-center-settings.component'
                  ).then((m) => m.LeadCenterSettingsComponent),
                title: 'Lead Extractor | Settings | Lead Center | MT',
              },
            ],
          },
          {
            path: 'activities',
            loadComponent: () =>
              import(
                './pages/lead-center-activities/lead-center-activities.component'
              ).then((m) => m.LeadCenterActivitiesComponent),
            title: 'Activities | Lead Center | MT',
          },
          {
            path: 'workflows',
            children: [
              {
                path: 'overview',
                loadComponent: () =>
                  import(
                    './pages/lead-center-workflows-overview/lead-center-workflows-overview.component'
                  ).then((m) => m.LeadCenterWorkflowsOverviewComponent),
                title: 'Overview | Workflows | Lead Center | MT',
              },
              {
                path: 'block-library',
                loadComponent: () =>
                  import(
                    './pages/lead-center-workflows-block-library/lead-center-workflows-block-library.component'
                  ).then((m) => m.LeadCenterWorkflowsBlockLibraryComponent),
                title: 'Block Library | Workflows | Lead Center | MT',
              },
              {
                path: 'scenarios',
                loadComponent: () =>
                  import(
                    './pages/lead-center-workflows-scenarios/lead-center-workflows-scenarios.component'
                  ).then((m) => m.LeadCenterWorkflowsScenariosComponent),
                title: 'Scenarios | Workflows | Lead Center | MT',
              },
              {
                path: ':_id',
                loadComponent: () =>
                  import(
                    './pages/lead-center-workflow/lead-center-workflow.component'
                  ).then((m) => m.LeadCenterWorkflowComponent),
                title: 'Workflow | Workflows | Lead Center | MT',
              },
            ],
          },
        ],
      },
    ],
  },
  { path: 'login', component: LoginComponent, title: 'Login | MT' },
  { path: 'register', component: RegisterComponent, title: 'Register | MT' },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'Forgot Password | MT',
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    title: 'Reset Password | MT',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    title: 'Not Found | MT',
  },
];
