import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideMonacoEditor } from 'ngx-monaco-editor-v2';
import { MessageService } from 'primeng/api';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideMonacoEditor(),
    provideZoneChangeDetection({
      eventCoalescing: true,
      runCoalescing: true,
      ignoreChangesOutsideZone: true,
    }),
    provideRouter(routes),
    provideHttpClient(),
    MessageService,
    importProvidersFrom([BrowserAnimationsModule]),
  ],
};
